import { BoundsInterface } from "~bounds";
import { CanvasPosition, PointData } from "../../CanvasPosition"
import { EntityBase, EntityData } from "./entity-base";
import { StrokeEntity } from "./entity-stroke";


const dpi = window.devicePixelRatio;


interface TodoEntityData extends EntityData {

}




export class TodoEntity extends StrokeEntity{
    
    public x :number =0; 
    public y :number=0;
    public width   : number
    public height  : number
    protected data : TodoEntityData;

    public get _subject() {
        return this.getRelationship("subject") as EntityBase[];
    }


    // protected _currentState : number = 0;

    protected set _currentState(newState:number){
        if(!this.data ) return;
        this.data.state = newState;
    }

    protected get _currentState(){
        if(!this.data || !this.data.state ) return 0;
        return this.data.state;
    }


    public constructor( data :TodoEntityData) {
        super(data );
    
        if(data && data.state) {
            this._currentState=data.state;
        }

    }


    protected get defaultData (){
        return {
            scale:1.0,
            state:0
        }
    } 

    public recalcBounds(){
        // process all the points
        let minX =null,
        minY=null,
        maxX=null,
        maxY=null;

        if(!this.data || !this.data.points || this.data.points.length<2) return;

        const topLeft = this.data.points[0];
        const bottomRight = this.data.points[1];
        
        this.data.size = Math.max( Math.abs(bottomRight.x - topLeft.x), Math.abs(bottomRight.y - topLeft.y) ); 
        this.data.points.forEach(item => {
            if(maxX===null || item.x > maxX) maxX = item.x;
            if(minX===null || item.x < minX) minX = item.x;

            if(maxY===null || item.y > maxY) maxY = item.y;
            if(minY===null || item.y < minY) minY = item.y;
        });

        // const strokeWidth = this.data.size;

        // store the bounds
        this.x = minX ;
        this.y = minY ;
        this.width = maxX - minX ;
        this.height = maxY - minY ;

        this.canvas.width = this.width;
        this.canvas.height = this.height;
        this.stale = true;

    }
 

    public get currentState(){
        return this._currentState;

    }

    public setState(newState:number){
        
        switch(this._currentState) {
            case 0:
        }

        this._currentState = newState;

        switch(this._currentState) {
            case 1:

        }
    }

    public onHandle(x,y, positionData:CanvasPosition){
        const checkboxSize = 40 * positionData.dpi;
        const topLeft = this.data.points[0];
        

        
        const padding = 8;
       
        if(x >topLeft.x - padding) return false;
        if(x < topLeft.x - padding - checkboxSize ) return false;
        if(y >topLeft.y+ checkboxSize) return false;
        if(y < topLeft.y ) return false;
        return true;
        // return topLeft.x - checkboxSize - padding, topLeft.y , checkboxSize, checkboxSize);
    }

    public toggle(){
        if(this._currentState==1){
            this.setState(2);
            return;
        }

        if(this._currentState==2){
            this.setState(1);
            return;
        }
    }

    public setSubject(ents:EntityBase[]){
        const newEnts = [];

        // filter them
        for(const entity of ents) {
            if( (entity.id !== this.id) &&  !(entity instanceof TodoEntity)) {
                newEnts.push(entity);
            }
        }
        
        this.addRelationship("subject", newEnts);
    }

    public draw(positionData:CanvasPosition , ctx : CanvasRenderingContext2D) : void{

       
        const outerContext = ctx;

        let scale = 1.0;
        if(this.data.scale){
            scale = this.data.scale;

            // if(scale < 1.0) scale = 1.0;
        }

        const strokeWidth = 10 * scale;
        scale = 1.0


            // ctx = this.canvas.getContext('2d')
            // ctx.clearRect(0,0,this.canvas.width,this.canvas.height)

            // console.log('draw rect', this.x, this.y, this.width, this.height)
            ctx.strokeStyle = this.data.colors[0];
            ctx.lineWidth = strokeWidth;

            let lastPoint = null;

            const checkboxSize = 40 * positionData.dpi;
            const padding = 8;

            if(this.data.points && this.data.points.length >1) {
                ctx.beginPath();
                ctx.lineWidth = 4;

                const topLeft = this.data.points[0];
                const bottomRight = this.data.points[1];

                // draw the checkbox
                if(this._currentState) {
                    ctx.strokeStyle = this.data.colors[0];
                    ctx.rect(topLeft.x - checkboxSize - padding, topLeft.y , checkboxSize, checkboxSize);
                    ctx.stroke();

                    if(this._currentState ==2){
                        ctx.fillStyle = this.data.colors[0];
                        ctx.fill();
                    }
                } 
                
                // draw the bounds
                // ctx.beginPath();
                // ctx.strokeStyle = "rgb(.9,.9,.9,0.1)"
                // ctx.rect(topLeft.x , topLeft.y , bottomRight.x - topLeft.x, bottomRight.y - topLeft.y);
                // ctx.stroke();
                
                // cross out the subject
                if(this._subject && this._subject.length && this._currentState==2){

                    const bounds = this._subject.map( entity => entity.getBounds())
                    const totalBounds = this.getTotalBounds(bounds)

                    ctx.beginPath();
                    ctx.moveTo( totalBounds.x + Math.cos(totalBounds.x/100.0) *20 , totalBounds.y  + Math.sin(totalBounds.x/130.0) *20)
                    ctx.lineTo( totalBounds.x2 + Math.cos(totalBounds.x2/100.0) *20 , totalBounds.y2 + Math.sin(totalBounds.y2/130.0) *20)

                    ctx.moveTo( totalBounds.x2 + Math.cos(totalBounds.x2/100.0) *20, totalBounds.y +Math.sin(totalBounds.y/210.0) *20)
                    ctx.lineTo( totalBounds.x + Math.cos(totalBounds.x/100.0) *20 , totalBounds.y2 + Math.sin(totalBounds.y2/100.0) *20)
                    ctx.lineWidth = 15;
                    ctx.stroke();
                }
                

                

            }

        

        // outerContext.drawImage(this.canvas, this.x, this.y)

    }

    getTotalBounds(boundsList: BoundsInterface[]):{x:number, y:number, x2:number, y2:number} {
        const startingPoint = boundsList[0];
        
        
        const totalBounds = {x:startingPoint.x, y:startingPoint.y, x2:startingPoint.x, y2:startingPoint.y}
        boundsList.forEach(bounds =>{
            
            if(totalBounds.x > bounds.x) totalBounds.x = bounds.x;
            if(totalBounds.y > bounds.y) totalBounds.y = bounds.y;
            if(totalBounds.x2 < bounds.x + bounds.width) totalBounds.x2 = bounds.x + bounds.width;
            if(totalBounds.y2 < bounds.y + bounds.height) totalBounds.y2 = bounds.y + bounds.height;
           
        })

        return totalBounds;
        
    }
}
