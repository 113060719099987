
import { EntityData } from "./entity-base"
import { CanvasEntity } from "./entity-canvas";
import { ShapeEntity } from "./entity-shape";
import { StrokeEntity } from "./entity-stroke";
import { TodoEntity } from "./entity-todo";

const typeMap = {ShapeEntity,StrokeEntity,TodoEntity, CanvasEntity}

export const dataToEntity  = ( json : {id:number, type:string, data:EntityData}) =>{
    if(!new typeMap[json.type]) return null;
    return new typeMap[json.type](json.data)

}