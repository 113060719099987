import { Bounds } from "~bounds";
import { CanvasPosition, PointData } from "../../CanvasPosition"
import { EntityBase, EntityData } from "./entity-base";

export interface TouchData{
    x       ?:number, 
    y       ?:number,
    targetX ?: number,
    targetY ?: number, 
    type    ?:string,
    history: any[]
}




const dpi = window.devicePixelRatio;




export class StrokeEntity extends EntityBase{
    public x :number =0; 
    public y :number=0;
    public width   :number
    public height  :number
    public canvas  :HTMLCanvasElement
    protected _path : Path2D
    

    public stale   :boolean = true 

    public constructor( data :EntityData) {
        super(data );
        this.canvas = document.createElement('canvas');
        

        const defaultColors = ["#000000"];

        // load up some default colors
        if(!this.data.colors || !data.colors.length) {
            this.data.colors = [...defaultColors];
        }

        this.recalcBounds();
    }


    public recalcBounds(){
        // process all the points
        let minX =null,
        minY=null,
        maxX=null,
        maxY=null;

        if(!this.data || !this.data.points) return;

        this.data.points.forEach(item => {
            if(maxX===null || item.x > maxX) maxX = item.x;
            if(minX===null || item.x < minX) minX = item.x;

            if(maxY===null || item.y > maxY) maxY = item.y;
            if(minY===null || item.y < minY) minY = item.y;
        });

        const strokeWidth = this.data.size;

        // store the bounds
        this.x = minX - strokeWidth;
        this.y = minY - strokeWidth;
        this.width = maxX - minX + strokeWidth*2;
        this.height = maxY - minY + strokeWidth*2;

        this.canvas.width = this.width;
        this.canvas.height = this.height;
        this.stale = true;


        this._path = new Path2D();
        const path = this._path;
        const points = this.data.points;
        let i=0;
        for(i=0; i<points.length-2; i++){
            var xc = (points[i].x + points[i + 1].x) / 2;
            var yc = (points[i].y + points[i + 1].y) / 2;
            path.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
        }
        path.quadraticCurveTo(points[i].x, points[i].y, points[i+1].x,points[i+1].y);
            
    }

    public getBounds(){
        return new Bounds(this.x, this.y, this.width, this.height);
    }

    public inBounds(x,y, width, height){
        if(isNaN(x)) {
            y = x.y;
            width = x.width;
            height = x.height;
            x = x.x as number;
        }
        const bounds = this.getBounds();
        if(bounds.x > x+width) return false;
        if(bounds.width+bounds.x <x) return false;
        if(bounds.y > y + height) return false;
        if(bounds.y +bounds.height < y) return false;

        return true;
    }

    public addPoint(point:PointData) {
        // let lastPoint = this.data.points[this.data.points.length-1]
        // if( lastPoint && Math.sqrt((lastPoint.x - point.x) ** 2 + (lastPoint.y - point.y) ** 2) > 2.0) {
        //     this.data.points.push(point);
            
        // }else {
        //     this.data.points[this.data.points.length-1] = {x: (lastPoint.x * .75 + point.x * .25) , y:(lastPoint.y *.75 + point.y * .25)   }
        //     // console.log('skipped!')
        // }

        this.data.points.push(point);


        this.recalcBounds();
        
    }


    /**
     * @description update a point - without an index it will grab the latest point (like a stack)
     * @param point 
     */
    public updatePoint(point:PointData, pointIndex:number=null) {
        
        if(!pointIndex){
            pointIndex = this.data.points.length-1;
        }
        this.data.points[pointIndex] = point;

        
        this.recalcBounds();
        
    }

    /**
     * @param point if pointIndex is not set, the most recent point will be returned. If negative it will get that from the top 
     *  
     */
    public getPoint(pointIndex:number = -1) {
    
        if(pointIndex<0) {
            pointIndex = this.data.points.length + pointIndex;
        }

        if(pointIndex<0 || pointIndex > this.data.points.length-1) return null;
        return this.data.points[pointIndex];
        
    }
    
    

    public onDraw(positionData:CanvasPosition , ctx : CanvasRenderingContext2D) : void{

       
        const outerContext = ctx;
        ctx.lineJoin = "round"
        ctx.lineCap = "round"

        let scale = 1.0;
        if(this.data.scale){
            scale = this.data.scale;

            // if(scale < 1.0) scale = 1.0;
        }

        const strokeWidth = this.data.size * scale;
        scale = 1.0


            // ctx = this.canvas.getContext('2d')
            // ctx.clearRect(0,0,this.canvas.width,this.canvas.height)

            // console.log('draw rect', this.x, this.y, this.width, this.height)
            ctx.strokeStyle = "#eeeeee";
            ctx.lineWidth = strokeWidth;
            const circleRadius = strokeWidth;

            let lastPoint = null;
            ctx.fillStyle = this.data.colors[0];
            ctx.strokeStyle = this.data.colors[0];
            ctx.lineWidth = circleRadius*2;

            const points = this.data.points;
            // ctx.beginPath()
            // let i=0;
            // for(i=0;i<points.length-2; i++){
            //     var xc = (points[i].x + points[i + 1].x) / 2;
            //     var yc = (points[i].y + points[i + 1].y) / 2;
            //     ctx.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
            // }
            // ctx.quadraticCurveTo(points[i].x, points[i].y, points[i+1].x,points[i+1].y);
      
   
 // curve through the last two points
 



            // this.data.points.forEach( (point, index) =>{
                

            //     // keep track of the previous point
            //     if(lastPoint) {
                    

            //         // const wiggleAngle = Math.random();
            //         ctx.lineTo(point.x * (scale), (point.y )* (scale))
            //         ctx.quadraticCurveTo()
            //         // ctx.lineTo((point.x + Math.cos(wiggleAngle) * 10)* (scale), (point.y+ Math.sin(wiggleAngle) * 10 )* (scale))
                    
            //     } else {
            //         ctx.beginPath();
            //         ctx.moveTo( (point.x ) * (scale), (point.y )* (scale));
            //     }

            //     // ctx.fillStyle = "#ff0000";
            //     // ctx.lineWidth = 0;
            //     // ctx.beginPath();
            //     // ctx.arc(point.x , point.y , circleRadius, 0,Math.PI*2);
            //     // ctx.closePath();
            //     // ctx.fill();

            //     lastPoint = point;
                
            // })
            // ctx.closePath();
            if(this._path){
                ctx.stroke(this._path);
            }
            // this.data.points.forEach( (point, index) =>{

            //     ctx.fillStyle = "#ff0000";
            //     ctx.lineWidth = 0;
            //     ctx.beginPath();
            //     ctx.arc(point.x , point.y , 4*dpi, 0,Math.PI*2);
            //     ctx.closePath();
            //     ctx.fill();
            // });
            this.stale = false;
        

        // outerContext.drawImage(this.canvas, this.x, this.y)

    }


}